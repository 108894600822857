import { useState, useEffect } from "react";
import { Col, Row, Alert } from "react-bootstrap";

export const Newsletter = ({ status, message, onValidated }) => {
  return (
    <Col lg={12}>
      <div className="newsletter-bx wow slideInUp">
        <h3>
          We cannot solve our problems with the same thinking, <br />
          we used when we created them.
        </h3>
        <div className="quoted-by">
          <span>~Albert Einstein</span>
        </div>
      </div>
    </Col>
  );
};
