import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="skill" id="skills">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <h2>Skills</h2>
              <p>
                E-commerce development, portfolio creation, and restaurant menu
                design defines our skill set.
                <br /> We excel in crafting engaging digital experiences,
                setting industry standards with <br /> innovative solutions
                tailored to your online success.
              </p>
              <Carousel
                responsive={responsive}
                infinite={true}
                className="owl-carousel owl-theme skill-slider"
                autoPlay
                autoPlaySpeed={2000}
                showDots
              >
                <div className="item">
                  <i className="fa-solid fa-shop"></i>
                  <h5>E-Commerce Websites</h5>
                </div>
                <div className="item">
                  <i className="fa-solid fa-user-tie"></i>
                  <h5>Portfolio Websites</h5>
                </div>
                <div className="item">
                  <i class="fa-solid fa-utensils"></i>
                  <h5>Restaurant Websites</h5>
                </div>
                <div className="item">
                  <i className="fa-solid fa-qrcode"></i>
                  <h5>QR Code</h5>
                </div>
                <div className="item">
                  <i className="fa-solid fa-mobile-screen"></i>
                  <h5>Mobile Development</h5>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      {/* <img className="background-image-left" src={colorSharp} alt="Image" /> */}
    </section>
  );
};
