import { Container, Row, Col, Tab, Nav, Carousel } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/portfolio_project1.png";
import projImg4 from "../assets/img/portfolio_project2.png";
import projImg2 from "../assets/img/shoppingcart1.png";
import projImg5 from "../assets/img/shoppingcart2.png";
import projImg3 from "../assets/img/CvMaker1.png";
import projImg6 from "../assets/img/CvMaker2.png";
import projImg7 from "../assets/img/portfolio4.png";
import projImg8 from "../assets/img/portfolio4-2.png";
import projImg9 from "../assets/img/ecommerce1.png";
import projImg10 from "../assets/img/ecommerce2.png";

import colorSharp2 from "../assets/img/color-sharp2.png";
import "animate.css";
import TrackVisibility from "react-on-screen";

export const Projects = () => {
  const project1 = [
    {
      title: "Portfolio Website",
      description: "Design & Development",
      imgUrl: projImg7,
    },

    {
      title: "Portfolio Website",
      description: "Design & Development",
      imgUrl: projImg8,
    },
  ];

  const project2 = [
    {
      title: "E-Commerce Website",
      description: "Design & Development",
      imgUrl: projImg2,
    },
    {
      title: "E-Commerce Website",
      description: "Design & Development",
      imgUrl: projImg5,
    },
  ];

  const project3 = [
    {
      title: "CV Maker Website",
      description: "Design & Development",
      imgUrl: projImg3,
    },
    {
      title: "CV Maker Website",
      description: "Design & Development",
      imgUrl: projImg6,
    },
  ];

  const project4 = [
    {
      title: "Portfolio Website",
      description: "Design & Development",
      imgUrl: projImg1,
    },
    {
      title: "Portfolio Website",
      description: "Design & Development",
      imgUrl: projImg4,
    },
  ];

  const project5 = [
    {
      title: "E-Commerce Website",
      description: "Design & Development",
      imgUrl: projImg9,
    },
    {
      title: "E-Commerce Website",
      description: "Design & Development",
      imgUrl: projImg10,
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <h2>Projects</h2>
                  <p>Explore our portfolio...</p>
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Nav
                      variant="pills"
                      className="nav-pills mb-5 justify-content-center align-items-center"
                      id="pills-tab"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="first">Tab 1</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">Tab 2</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">Tab 3</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="fourth">Tab 4</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="fifth">Tab 5</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="sixth">Tab 6</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content
                      id="slideInUp"
                      className={
                        isVisible ? "animate__animated animate__slideInUp" : ""
                      }
                    >
                      <Tab.Pane eventKey="first">
                        <Carousel>
                          {project1.map((project, index) => (
                            <Carousel.Item key={index}>
                              <ProjectCard {...project} />
                            </Carousel.Item>
                          ))}
                        </Carousel>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <Carousel>
                          {project2.map((project, index) => (
                            <Carousel.Item key={index}>
                              <ProjectCard {...project} />
                            </Carousel.Item>
                          ))}
                        </Carousel>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <Carousel>
                          {project3.map((project, index) => (
                            <Carousel.Item key={index}>
                              <ProjectCard {...project} />
                            </Carousel.Item>
                          ))}
                        </Carousel>
                      </Tab.Pane>
                      <Tab.Pane eventKey="fourth">
                        <Carousel>
                          {project4.map((project, index) => (
                            <Carousel.Item key={index}>
                              <ProjectCard {...project} />
                            </Carousel.Item>
                          ))}
                        </Carousel>
                      </Tab.Pane>
                      <Tab.Pane eventKey="fifth">
                        <Carousel>
                          {project5.map((project, index) => (
                            <Carousel.Item key={index}>
                              <ProjectCard {...project} />
                            </Carousel.Item>
                          ))}
                        </Carousel>
                      </Tab.Pane>
                      <Tab.Pane eventKey="sixth">
                        <Carousel>
                          <p>Posted until available</p>
                        </Carousel>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img
        className="background-image-right"
        src={colorSharp2}
        alt="background"
      />
    </section>
  );
};
