import { Container, Row, Col } from "react-bootstrap";
import { MailchimpForm } from "./MailchimpForm";
import logo from "../assets/img/brand.webp";
import navIcon1 from "../assets/img/nav-icon1.svg";
import navIcon2 from "../assets/img/nav-icon2.svg";
import navIcon3 from "../assets/img/nav-icon3.svg";

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <MailchimpForm />
          <Col size={12} sm={6} className="footer-logo">
            <img src={logo} alt="Logo" />
          </Col>
          <Col size={12} sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="https://wa.me/96181255611" target="_blank">
                <img src={navIcon1} alt="" />
              </a>
              <a
                href="https://www.tiktok.com/@gitandgrow?lang=en"
                target="_blank"
              >
                <img src={navIcon2} alt="" />
              </a>
              <a href="https://www.instagram.com/git_and_grow/" target="_blank">
                <img src={navIcon3} alt="Icon" />
              </a>
            </div>
            <p>Copyright 2024 &#169; Git And Grow.All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
